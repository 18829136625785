import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {AppConfig} from "../config/app-config";
import {DecimalPipe} from "@angular/common";

@Pipe({name: 'grade', pure: true})
export class GradeNamePipe implements PipeTransform {

  ratings = {
    de: ['sehr gut', 'gut', 'befriedigend', 'ausreichend', 'mangelhaft'],
    it: ['molto buono', 'buono', 'soddisfacente', 'sufficiente', 'respinto'],
    en: ['excellent', 'good', 'satisfactory', 'sufficient', 'fail'],
    fr: ['très bien', 'bien', 'satisfaisant', 'suffisant', 'médiocre']
  }

  constructor(private config: AppConfig, private decimalPipe: DecimalPipe) {
  }

  transform(grade: number, format: 'number' | 'text' = 'number'): string | number {

    if (format == 'number') {
      if (this.config.locale != 'de') {
        grade = (6 - grade) * 2;
        if (grade >= 9.95)
          return '10';
      }
      return this.decimalPipe.transform(grade, '1.1-1');
    }


    let rating;
    let ratings = this.ratings[this.config.locale];

    if (grade <= 1.5) {
      rating = ratings[0];
    } else if (grade > 1.5 && grade < 2.5) {
      rating = ratings[1];
    } else if (grade >= 2.5 && grade <= 3.5) {
      rating = ratings[2];
    } else if (grade > 3.5 && grade <= 4.5) {
      rating = ratings[3];
    } else if (grade > 4.5) {
      rating = ratings[4];
    }

    return rating;
  }

}

@NgModule({
  imports: [],
  exports: [
    GradeNamePipe,
  ],
  declarations: [
    GradeNamePipe,
  ],
  providers: []
})
export class GradeNamePipeModule {
}

